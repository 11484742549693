import { Injectable, Injector } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Observable, Observer, Subscription, from, of, throwError } from 'rxjs';
import { catchError, concatMap, delay, map, mergeMap, retry } from 'rxjs/operators';
import { BluetoothDevice, BluetoothDeviceMode, BluetoothDeviceType } from '../../../models/bluetooth-device.model';
import { BrowserUtils } from '../../../utils';
import { LocalSettingsService } from '../../local-settings/local-settings.service';
import { ScannerService } from '../../scanner/scanner.service';
import { BasePlugin } from '../base-plugin';
import { SetSettingsService } from '../../protobuf/set-settings.service';
declare const cordova;


export interface BluetoothSerialPluginSettings {
  connectDelayInMS: number;
  connectOverride: any;
  connectTimeoutInMS: number;
  retryIntervalInMs: number;
  retryAttempts: number;
  readDelimiter: string | undefined;
  writeChunkSize: number;
  writeDelayInMS: number;
  writeTimeoutInMS: number;
}


@Injectable({
  providedIn: 'root'
})
export class BluetoothSerialPlugin extends BasePlugin {

  private readonly alwaysOnDeviceTypes: string[] = [
    BluetoothDeviceType.Scanner,
  ];

  private settings: BluetoothSerialPluginSettings;
  private defaultPluginInstance: string;
  private deviceToPluginInstanceMap: { [deviceId: string]: string } = {};
  private deviceToStartSubscriptionMap: { [deviceId: string]: string } = {};
  private deviceToStartCallbackMap: { [deviceId: string]: any } = {};
  private connectedDevices: BluetoothDevice[];
  private inactiveTimeoutMap: { [key: string]: any } = {};
  private isPluginAllowedChecked: boolean;
  private pluginInstances: string[];
  private printerSatoResponseMap: { [deviceId: string]: any } = {};
  private printerSatoSeqMap: { [deviceId: string]: number } = {};
  private printerSatoTimeoutMap: { [deviceId: string]: any } = {};
  private printerSatoWriteObserverMap: { [deviceId: string]: Observer<any> } = {};
  private reconnectTimeoutMap: { [deviceId: string]: any } = {};
  private retryAttemptsMap: { [deviceId: string]: number } = {};
  private writeInProgress: boolean;

  // no longer using BluetoothSerial directly as to support multiple connections I had to use a HACK
  // described here: https://github.com/don/BluetoothSerial/issues/58
  constructor(
    // private bluetoothSerial: BluetoothSerial,
    injector: Injector,
    private localSettingsService: LocalSettingsService,
    private platform: Platform,
    private scannerService: ScannerService,
    private setSettingsService: SetSettingsService,
  ) {
    super(injector);

    this.pluginName = 'BtSerialPlugin';

    this.settings = {
      connectDelayInMS: 250,
      connectOverride: undefined,
      connectTimeoutInMS: 2 * 1000,
      retryIntervalInMs: 2 * 1000,
      retryAttempts: 2,
      readDelimiter: undefined,
      writeChunkSize: 0, // 0 -> send it all in one go...
      writeDelayInMS: 250,
      writeTimeoutInMS: 5 * 1000,
    };

    this.defaultPluginInstance = this.platform.is('ios') ? 'BluetoothSerial' : 'BluetoothSerial1';
    this.pluginInstances = [
      'BluetoothSerial1',
      'BluetoothSerial2',
      'BluetoothSerial3',
    ];
  }

  isPluginAllowed(): boolean {
    return BrowserUtils.isDeviceApp() && this.platform.is('android');
  }

  initialize(options?: any): Observable<null> {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Cordova not available...');
      this.isPluginAllowedChecked = true;
      return of(null);
    }

    Object.assign(this.settings, options || {});
    this.connectedDevices = this.localSettingsService.getBtDevices(BluetoothDeviceMode.Classic);

    return this.initAndHandleConnections(this.connectedDevices, options?.forceEnable || this.connectedDevices.length > 0);
  }

  private initAndHandleConnections(connectedDevices: BluetoothDevice[], forceEnable: boolean): Observable<null> {
    return this.checkAndHandleEnabledState(forceEnable)
    .pipe(
      map((isEnabled: boolean) => {
        if (isEnabled && connectedDevices?.length > 0) this.checkAndHandleConnectedState(connectedDevices);

        return null;
      })
    );
  }

  private checkAndHandleEnabledState(forceEnable: boolean): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.log('Checking BT enabled state...');

      cordova.exec(
        () => {
          this.log('Enabled');
          observer.next(true);
          observer.complete();
        },
        (error: any) => {
          this.log(error);
          if (forceEnable) {
            this.enable(observer);
            return;
          }

          observer.next(false);
          observer.complete();
        },
        this.defaultPluginInstance, // Always use first plugin instance to perform non device / connection specific calls
        'isEnabled',
        []
      );
    });
  }

  private enable(observer: Observer<boolean>) {
    this.log('Enabling BT...');

    cordova.exec(
      (success: any) => {
        this.log(success);
        observer.next(true);
        observer.complete();
      },
      (error: any) => {
        this.log(error);

        setTimeout(() => {
          this.enable(observer);
        }, this.settings.retryIntervalInMs);
      },
      this.defaultPluginInstance, // Always use first plugin instance to perform non device / connection specific calls
      'enable',
      []
    );
  }

  private checkAndHandleConnectedState(connectedDevices: BluetoothDevice[]) {
    this.log('Checking BT devices connected state...');
    for (const device of connectedDevices || []) {
      // Iterate over all the known devices and check if any of them is connected.
      // If yes, disconnect. If any of them is a AlwaysOnDevice, connect to it.
      this.isConnected(device)
      .subscribe({
        next: (pluginInstance: string | null) => {
          if (pluginInstance) {
            this.log(`Connected, but shouldn't be. Disconnecting...`);

            device.isConnected = false;
            this.disconnect(device, false)
            .pipe(
              delay(this.settings.connectDelayInMS)
            )
            .subscribe({
              next: () => {
                this.ifAlwaysOnDeviceTryToConnect(device);
              },
              error: (error: any) => {
                this.log(`Error trying to disconnect from '${device.id}': ` + JSON.stringify(error));

                this.ifAlwaysOnDeviceTryToConnect(device);
              }
            });
          } else {
            this.log(`Disconnected from '${device.id}', as it should be at this point.`);

            this.ifAlwaysOnDeviceTryToConnect(device);
          }
        },
        error: (error: any) => {
          // do nothing...
        }
      });
    }
  }

  private isConnected(device: BluetoothDevice): Observable<string | null> {
    return new Observable((observer: Observer<string | null>) => {
      const pluginInstance = this.deviceToPluginInstanceMap[device.id] || this.defaultPluginInstance;
      cordova.exec(
        (success: any) => {
          observer.next(pluginInstance);
          observer.complete();
        },
        (error: any) => {
          observer.next(null);
          observer.complete();
        },
        pluginInstance,
        'isConnected',
        []
      );
    });
  }

  private ifAlwaysOnDeviceTryToConnect(device: BluetoothDevice) {
    if (this.alwaysOnDeviceTypes.indexOf(device.type) < 0) return;

    this.log(`Trying to (re)connect to '${device.id}' (AlwaysOnDevice)...`);
    const connectMethod = this.settings.connectOverride || this.actionConnect.bind(this);
    connectMethod({
      device: device,
      skipIsConnectedCheck: true,
    })
    .subscribe({
      error: (error: any) => {
        this.log('ifAlwaysOnDeviceTryToConnect: ' + (error?.message || error));
      }
    });
  }

  private resetPluginInstance(device: BluetoothDevice) {
    const pluginInstance = this.deviceToPluginInstanceMap[device.id];
    if (pluginInstance) {
      this.pluginInstances.push(pluginInstance);
      delete this.deviceToPluginInstanceMap[device.id];
    }

    delete this.deviceToStartSubscriptionMap[device.id];
    delete this.deviceToStartCallbackMap[device.id];

    delete this.inactiveTimeoutMap[device.id];
    delete this.reconnectTimeoutMap[device.id];
  }

  action(options?: any): Observable<any> {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Cordova not available...');
      this.isPluginAllowedChecked = true;
      return of(null);
    }

    if (options.command !== 'list' && options.command !== 'discover' && options.device && this.inactiveTimeoutMap[options.device.id]) {
      clearTimeout(this.inactiveTimeoutMap[options.device.id]);
      delete this.inactiveTimeoutMap[options.device.id];
    }

    if (['start', 'stop', 'disconnectOnInactivity', 'reconnectTimeout'].indexOf(options.command) < 0) {
      this.log(`Action '${options.command}' for device '${options.device?.id || ''}' started: ${JSON.stringify(options || {}, (key, value) => key !== 'device' ? value : undefined, 1)}`);
    }
    switch(options.command) {
      case 'list':
        if (this.platform.is('ios')) {
          return of([]); // Not supported on ios
        } else {
          return new Observable((observer: Observer<BluetoothDevice[]>) => {
            cordova.exec(
              (devices: BluetoothDevice[]) => {
                this.connectedDevices = this.localSettingsService.getBtDevices(BluetoothDeviceMode.Classic);

                devices = (devices || []);
                devices.push(...this.connectedDevices);

                devices = BluetoothDevice.removeDuplicatesDevices(devices)
                .map((d: BluetoothDevice) => {
                  d.mode = BluetoothDeviceMode.Classic;
                  const knownDevice = this.getKnownDevice(d);
                  if (knownDevice) {
                    knownDevice.shouldBeConnected = true;
                    return knownDevice;
                  } else {
                    d.shouldBeConnected = false;
                    d.isConnected = false;
                    d.type = BluetoothDeviceType.Unknown;
                    return new BluetoothDevice(d);
                  }
                });

                if (this.localSettingsService.get().runDeviceDebug) {
                  this.log(`Action 'list' found ${devices.length} devices: ${JSON.stringify(devices)}`);
                } else {
                  this.log(`Action 'list' found ${devices.length} devices.`);
                }

                observer.next(devices);
                observer.complete();
              },
              (error: any) => {
                this.log(error);

                observer.next(this.connectedDevices);
                observer.complete();
              },
              'BluetoothSerial1', // Always use first plugin instance to perform non device / connection specific calls
              'list',
              []
            );
          });
        }
      case 'discover':
        if (this.platform.is('ios')) {
          return of([]); // Not supported on ios
        } else {
          return new Observable((observer: Observer<BluetoothDevice[]>) => {
            cordova.exec(
              (devices: BluetoothDevice[]) => {
                devices = BluetoothDevice.removeDuplicatesDevices(devices)
                .map((d: BluetoothDevice) => {
                  d.mode = BluetoothDeviceMode.Classic;
                  const knownDevice = this.getKnownDevice(d);
                  if (knownDevice) {
                    knownDevice.shouldBeConnected = true;
                    return knownDevice;
                  } else {
                    d.shouldBeConnected = false;
                    d.isConnected = false;
                    d.type = BluetoothDeviceType.Unknown;
                    return new BluetoothDevice(d);
                  }
                });

                if (this.localSettingsService.get().runDeviceDebug) {
                  this.log(`Action 'discover' found ${devices.length} devices: ${JSON.stringify(devices)}`);
                } else {
                  this.log(`Action 'discover' found ${devices.length} devices.`);
                }
                observer.next(devices);
                observer.complete();
              },
              (error: any) => {
                this.log(error);
                observer.error(error);
              },
              'BluetoothSerial1', // Always use first plugin instance to perform non device / connection specific calls
              'discoverUnpaired',
              []
            );
          });
        }
      case 'connect':
        options.skipAlwaysOnDeviceAutoRetries = true;
        return this.retryActionIfNeeded(
          this.actionConnect.bind(this),
          options
        );
      case 'disconnect':
        return this.disconnect(options.device, options.unpair);
      case 'disconnectOnInactivity':
        this.inactiveTimeoutMap[options.device.id] = setTimeout(() => {
          if (!this.inactiveTimeoutMap[options.device.id]) return;

          this.disconnect(options.device, options.unpair).subscribe();
        }, options.device.settings.disconnectOnInactivityTimeoutMS);

        return of(null);
      case 'read':
        return of(null);
      case 'reconnectTimeout':
        if (this.reconnectTimeoutMap[options.device.id]) {
          clearTimeout(this.reconnectTimeoutMap[options.device.id]);
          delete this.reconnectTimeoutMap[options.device.id];
        }
        this.reconnectTimeoutMap[options.device.id] = setTimeout(() => {
          if (!this.reconnectTimeoutMap[options.device.id]) return;

          const connectMethod = this.settings.connectOverride || this.actionConnect.bind(this);
          this.retryActionIfNeeded(
            connectMethod.bind(this),
            options
          ).subscribe();
        }, options.device.settings.reconnectTimeoutMS);

        return of(null);
      case 'requestMtu':
        return of(null);
      case 'start':
        return this.retryActionIfNeeded(
          this.actionStart.bind(this),
          options
        );
      case 'stop':
        this.stop(options);
        return of(null);
      case 'write':
        return this.retryActionIfNeeded(
          this.actionWrite.bind(this),
          options
        );
    }

    return of(null);
  }

  private retryActionIfNeeded(actionMethod: any, options: any): Observable<any> {
    let currentRetryAttempt = 1;
    let retryAttempts = options.retryAttempts != null ? options.retryAttempts : this.settings.retryAttempts != null ? this.settings.retryAttempts : 0;
    let retryIntervalInMs = options.retryIntervalInMs != null ? options.retryIntervalInMs : this.settings.retryIntervalInMs != null ? this.settings.retryIntervalInMs : 0;

    if (!retryAttempts) return actionMethod(options);

    return actionMethod(options)
    .pipe(
      catchError((error: any) => {
        currentRetryAttempt++;
        if (currentRetryAttempt <= retryAttempts) this.log(`Retrying operation in ${~~(retryIntervalInMs / 1000)}s...`);
        return throwError(() => error);
      }),
      retry({
        delay: retryIntervalInMs,
        count: retryAttempts,
      })
    );
  }

  private actionConnect(options: any): Observable<boolean> {
    let initialRequest = this.isConnected(options.device);
    if (options?.skipIsConnectedCheck) {
      const pluginInstance = this.deviceToPluginInstanceMap[options.device.id];
      initialRequest = of(pluginInstance);
    }

    return initialRequest
    .pipe(
      mergeMap((pluginInstance: string | null) => {
        if (pluginInstance) {
          this.log(`Device '${options.device.id}' already connected on pluginInstance '${pluginInstance}'.`);
          if (!options.device.isConnected) {
            options.device.isConnected = true;
            this.localSettingsService.addOrUpdateBtDevice(options.device);
          }
          return of(false); // already connected, so don't need to connect again
        } else {
          options.device.isConnected = false;

          return this.connect(options.device, !options.shouldBeConnected, options.skipSaveSettingRemotely);
        }
      }),
      mergeMap((isNewlyConnected: boolean) => {
        if (options.device.type !== BluetoothDeviceType.PrinterSato) return of(null);
        if (!isNewlyConnected) return of(null);

        // For PrinterSato we request the printer information after connect and before doing anything else...
        return of(null)
        .pipe(
          delay(this.settings.connectDelayInMS),
          mergeMap(() => {
            // First we start listening for the printer information...
            return this.actionStart({
              device: options.device,
            });
          }),
          delay(this.settings.connectDelayInMS),
          mergeMap(() => {
            return this.actionWrite({
              command: 'write',
              device: options.device,
              writeDataRaw: JSON.stringify({
                // seq: this.printerSatoSeqMap[options.device.id],
                type: 3, // ManagedSocket, returns same response as 0103: PrinterComponents
                time: Date.now(),
                content: { }
              }),
              writeTimeoutInMS: 2 * 1000,
            });
          }),
          catchError((error: any) => {
            // if this fails, most likely this isn't a PRINTER-SATO or doesn't have the AEP connector installed, so we wanna disconnect
            // and let the user know about it
            return of(null)
            .pipe(
              delay(this.settings.connectDelayInMS),
              mergeMap(() => {
                return this.disconnect(options.device, false);
              }),
              mergeMap(() => {
                return throwError(() => error);
              })
            );
          })
        );
      }),
      delay(this.settings.connectDelayInMS), // give it an extra second to REALLLY connect to the printer
      map((response: any) => {
        options.device.shouldBeConnected = true;
        options.device.isConnected = true;
        this.localSettingsService.addOrUpdateBtDevice(options.device);

        return response;
      }),
      catchError((error: any) => {
        setTimeout(() => {
          if (!options.device.shouldBeConnected) return;
          if (options.skipAlwaysOnDeviceAutoRetries) return;

          this.ifAlwaysOnDeviceTryToConnect(options.device);
        }, this.settings.retryIntervalInMs)
        return throwError(() => error);
      }),
    );
  }

  private actionStart(options: any): Observable<void> {
    // action 'start' may not have options.device (we may want to subscribe to all), so we need to handle that
    const connectMethod = this.settings.connectOverride || this.actionConnect.bind(this);
    const connectIfNotAlreadyOptions = { device: options.device, retryAttempts: 0 };
    const initialRequestStart = (options.device && options.connectIfNotAlready ? connectMethod(connectIfNotAlreadyOptions) : of(null)) as Observable<any>;
    return initialRequestStart
    .pipe(
      map(() => {
        this.start(
          options.device?.type === BluetoothDeviceType.PrinterSato ? (d) => this.handlePrinterSatoResponses(options.device, d, options.callback)
          : options.callback ? (d) => options.callback(d)
          : this.handleScanData.bind(this),
          options,
        );
      })
    );
  }

  private actionWrite(options: any, skipLog?: boolean): Observable<boolean> {
    if (this.writeInProgress) {
      if (!skipLog) this.log(`Write in progress...Waiting to execute action: ${JSON.stringify(options || {}, (key, value) => key !== 'device' ? value : undefined, 1)}`);
      return of(null)
      .pipe(
        delay((options.writeDelayInMS || this.settings.writeDelayInMS) * 4),
        mergeMap(() => {
          return this.actionWrite(options, true);
        })
      );
    }

    const connectMethod = this.settings.connectOverride || this.actionConnect.bind(this);
    const connectIfNotAlreadyOptions = { device: options.device, retryAttempts: 0 };
    const initialRequestWrite = (options.connectIfNotAlready ? connectMethod(connectIfNotAlreadyOptions) : of(null)) as Observable<any>;
    return initialRequestWrite
    .pipe(
      mergeMap(() => {
        this.writeInProgress = true;

        return new Observable((observer: Observer<any>) => {
          setTimeout(() => {
            let writeData = options.writeDataRaw || options.writeData;
            if (options.device.type === BluetoothDeviceType.PrinterSato) {
              this.printerSatoSeqMap[options.device.id] = this.printerSatoSeqMap[options.device.id] != null ? this.printerSatoSeqMap[options.device.id] : 0;

              if (options.writeDataRaw) {
                try {
                  const writeDataJson = JSON.parse(options.writeDataRaw);
                  writeDataJson.seq = writeData.seq || ++this.printerSatoSeqMap[options.device.id];
                  writeDataJson.time = writeData.time || Date.now();

                  writeData = JSON.stringify(writeDataJson);
                } catch (error) {
                }
              } else if (options.writeData) {
                let isPayloadJsonObject = true;
                try {
                  JSON.parse(options.writeData);
                } catch (error) {
                  isPayloadJsonObject = false;
                }
                const type = options.device.settings?.rfid ? 22 : isPayloadJsonObject ? 20 : 21;
                writeData = JSON.stringify({
                  seq: ++this.printerSatoSeqMap[options.device.id],
                  type: type,
                  time: Date.now(),
                  content: !options.writeData ? { } : {
                    print: typeof options.writeData !== 'string' ? JSON.stringify(options.writeData) : options.writeData
                  }
                });
              }

              this.resetPrinterSatoTimeout(options);

              this.printerSatoWriteObserverMap[options.device.id] = observer;
              this.printerSatoResponseMap[options.device.id] = '';
              this.log(`PrinterSato payload: ${writeData}`);
            }

            this.writeStringToBluetoothDevice(options.device, writeData, observer);
          }, options.writeDelayInMS || this.settings.writeDelayInMS);
        }).pipe(
          mergeMap((response: any) => {
            this.writeInProgress = false;
            if (!options.device.settings?.disconnectOnInactivityTimeoutMS) return of(response);

            return this.action({ command: 'disconnectOnInactivity', device: options.device })
            .pipe(
              map(() => {
                return response;
              })
            );
          }),
          catchError((error: any) => {
            this.writeInProgress = false;
            const errorMsg = typeof error === 'string' ? error
            : error?.message ? error.message
            : JSON.stringify(error);
            this.log('Error writing data to device: ' + errorMsg);
            options.device.isConnected = false;
            if (options.device.settings?.disconnectOnInactivityTimeoutMS) {
              this.action({ command: 'disconnectOnInactivity', device: options.device }).subscribe();
            }
            return throwError(() => error);
          }),
        );
      })
    );
  }

  private resetPrinterSatoTimeout(options: any) {
    if (this.printerSatoTimeoutMap[options.device.id]) clearTimeout(this.printerSatoTimeoutMap[options.device.id]);
    this.printerSatoTimeoutMap[options.device.id] = setTimeout(() => {
      if (!this.printerSatoTimeoutMap[options.device.id] || !this.printerSatoWriteObserverMap[options.device.id]) return;

      if (this.printerSatoResponseMap[options.device.id]) {
        this.printerSatoWriteObserverMap[options.device.id].error('Timeout. Partial response from printer: ' + this.printerSatoResponseMap[options.device.id]);
      } else {
        this.printerSatoWriteObserverMap[options.device.id].error('Timeout. No response from printer.');
      }
      this.printerSatoResponseMap[options.device.id] = '';
    }, options.writeTimeoutInMS || this.settings.writeTimeoutInMS);
  }

  private connect(device: BluetoothDevice, firstTime: boolean, skipSaveSettingRemotely?: boolean): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      const pluginInstance = this.getPluginInstanceAvailableForConnection(device);
      if (!pluginInstance) {
        observer.error(`You've reached the max number of allowed BT Classic connections: ${this.pluginInstances.length}`);
        return;
      }

      this.log(`Connecting to '${device.id}' on pluginInstance '${pluginInstance}'...`);
      this.deviceToPluginInstanceMap[device.id] = pluginInstance;

      if (!(device as any).$connectTimeout) {
        (device as any).$connectTimeout = setTimeout(() => {
          if (!(device as any).$connectTimeout) return;

          this.log(`Connection timed-out for '${device.id}' on pluginInstance '${pluginInstance}'...`);
          this.disconnect(device, false)
          .subscribe({
            next: () => {
              this.failedConnectionHandler(device, firstTime, observer, new Error('Connection Timeout'));
            },
            error: (error: any) => {
              this.failedConnectionHandler(device, firstTime, observer, error);
            }
          });
        }, this.settings.connectTimeoutInMS);
      }

      const previousNow = performance.now();
      cordova.exec(
        (success: any) => {
          if ((device as any).$connectTimeout) {
            clearTimeout((device as any).$connectTimeout);
            delete (device as any).$connectTimeout;
          }
          firstTime = false; // this is used on (error) which gets called on failing to connect but also when/if the connection drops later
          this.log(`Connected to '${device.id}' on pluginInstance '${pluginInstance}' (${~~(performance.now() - previousNow)}ms).`);

          device.type = device.type || BluetoothDeviceType.Unknown;

          if (device.type !== BluetoothDeviceType.PrinterSato) {
            device.shouldBeConnected = true;
            device.isConnected = true;
            this.localSettingsService.addOrUpdateBtDevice(device);
            this.connectedDevices = this.localSettingsService.getBtDevices(BluetoothDeviceMode.Classic);
          }

          if (!skipSaveSettingRemotely) {
            this.setSettingsService.setBluetoothDeviceSettingRemotely(device, true);
          }

          observer.next(true);
          observer.complete();
        },
        (error: any) => {
          this.log(`Failed to connect to '${device.id}' on pluginInstance '${pluginInstance}': ${JSON.stringify(error)}`);

          this.failedConnectionHandler(device, firstTime, observer, error);
        },
        pluginInstance,
        'connect',
        [device.id]
      );
    });
  }

  private failedConnectionHandler(device: BluetoothDevice, firstTime: boolean, observer: Observer<any>, error: any) {
    if ((device as any).$connectTimeout) {
      clearTimeout((device as any).$connectTimeout);
      delete (device as any).$connectTimeout;
    }

    this.resetPluginInstance(device);
    device.isConnected = false;
    this.localSettingsService.addOrUpdateBtDevice(device);

    if (firstTime || this.alwaysOnDeviceTypes.indexOf(device.type) < 0) return observer.error(error);

    // If alwaysOnDevice keep retrying...
    setTimeout(() => {
      if (!device.shouldBeConnected) return;

      const connectMethod = this.settings.connectOverride || this.actionConnect.bind(this);
      connectMethod({
        device: device,
      })
      .subscribe({
        next: () => {
          observer.next(true);
          observer.complete();
        },
        error: (error: any) => {
          observer.error(error);
        }
      });
    }, device.settings.reconnectTimeoutMS || this.settings.retryIntervalInMs);
  }

  private getPluginInstanceAvailableForConnection(device: BluetoothDevice): string | null {
    if (this.deviceToPluginInstanceMap[device.id]) {
      return this.deviceToPluginInstanceMap[device.id];
    }
    if (this.pluginInstances.length) {
      return this.pluginInstances.splice(0, 1)[0];
    } else {
      return null;
    }
  }

  private disconnect(device: BluetoothDevice, unpair = false): Observable<null> {
    return new Observable((observer: Observer<null>) => {
      const pluginInstance = this.deviceToPluginInstanceMap[device.id] || this.defaultPluginInstance;
      this.log(`Disconnecting from '${device.id}' on pluginInstance '${pluginInstance}'...${unpair ? 'and unpairing.' : ''}`);

      if (!pluginInstance) {
        this.log(`Couldn't find pluginInstance for device which means it is not connected.`);
        this.actualDisconnectRoutine(device, unpair, observer);
        return;
      }

      cordova.exec(
        (success: any) => {
          this.log(`Disconnected from '${device.id}' on pluginInstance '${pluginInstance}': ${success}`);
          this.actualDisconnectRoutine(device, unpair, observer);
        },
        (error: any) => {
          this.log(`Failed to disconnected from '${device.id}' on pluginInstance '${pluginInstance}': ${JSON.stringify(error)}`);
          if (unpair) {
            this.log(`Removing nonetheless '${device.id}' from the list of connected devices...`);
            this.actualDisconnectRoutine(device, unpair, observer);
          } else {
            this.resetPluginInstance(device);
            observer.error(error);
          }
        },
        pluginInstance,
        'disconnect',
        []
      );
    });
  }

  private actualDisconnectRoutine(device: BluetoothDevice, unpair: boolean, observer: Observer<null>) {
    this.resetPluginInstance(device);
    device.isConnected = false;

    if (unpair) {
      device.shouldBeConnected = false;
      this.localSettingsService.removeBtDevice(device);
      this.setSettingsService.setBluetoothDeviceSettingRemotely(device, false);

      this.connectedDevices = this.localSettingsService.getBtDevices(BluetoothDeviceMode.Classic);
    } else {
      this.localSettingsService.addOrUpdateBtDevice(device);

      if (device.settings?.reconnectTimeoutMS) {
        this.action({ command: 'reconnectTimeout', device: device }).subscribe();
      }
    }

    observer.next(null);
    observer.complete();
  }

  private printerSatoWritePreviousNow: number;
  writeStringToBluetoothDevice(device: BluetoothDevice, str: string, observer: Observer<any>): Subscription {
    const writeChunkSize = device.settings.writeChunkSize != null ? device.settings.writeChunkSize : this.settings.writeChunkSize;
    const previousNow = performance.now();

    if (!writeChunkSize || str.length <= writeChunkSize) {
      return this.writeChunkToBluetoothDevice(
        device,
        str,
      ).subscribe({
        next: (response: any) => {
          if (device.type === BluetoothDeviceType.PrinterSato) {
            this.log(`Write data to device successfuly (${~~(performance.now() - previousNow)}ms). Waiting for response...`);
            this.printerSatoWritePreviousNow = performance.now();
            return;
          }

          const responseLogMsg = typeof response === 'string' ? response : JSON.stringify(response);
          this.log(`Write data to device successfuly (${~~(performance.now() - previousNow)}ms). Response: ${responseLogMsg}`);
          observer.next(response);
          observer.complete();
        },
        error: (error: any) => {
          this.log('Error writing data to BT device: ' + error);
          observer.error(error);
        }
      });
    } else {
      // Need to partion the string and write one chunk at a time.
      const chunks: string[] = [];
      let subStr = '';
      for (let i = 0; i < str.length; i += writeChunkSize) {
        if (i + writeChunkSize <= str.length) {
          subStr = str.substring(i, i + writeChunkSize)
        } else {
          subStr = str.substring(i, str.length)
        }
        chunks.push(subStr);
      }

      return from(chunks)
      .pipe(
        concatMap((chunkStr: string) => {
          return this.writeChunkToBluetoothDevice(
            device,
            chunkStr,
          ).pipe(
            map(() => {
              this.resetPrinterSatoTimeout({
                device: device
              })
            }),
            delay(100)
          );
        })
      ).subscribe(() => {

      }, (error: any) => {
        this.log('Error writing chunk to BT device: ' + error);
        observer.error(error);
      }, () => {
        if (device.type === BluetoothDeviceType.PrinterSato) return;

        this.log(`Write data to device successfuly (${~~(performance.now() - previousNow)}ms). Response: OK`);
        observer.next('OK');
        observer.complete();
      });
    }
  }

  private writeChunkToBluetoothDevice(device: BluetoothDevice, str: string): Observable<any> {
    // Convert str to ArrayBuffer and write to device
    const buffer = new ArrayBuffer(str.length)
    const dataView = new DataView(buffer)
    for (let i = 0; i < str.length; i++) {
      dataView.setUint8(i, str.charAt(i).charCodeAt(0))
    }

    // Alternative encoding...
    // const array = new Uint8Array(str.length);
    // for (let i = 0; i < str.length; i++) {
    //   array[i] = str.charCodeAt(i);
    // }
    // const buffer = array.buffer;

    return new Observable<any>((observer: Observer<any>) => {
      cordova.exec(
        (response: any) => {
          const responseLogMsg = typeof response === 'string' ? response : JSON.stringify(response);
          this.log(`Write chunk (size=${buffer.byteLength}) to device successfuly. Response: ${responseLogMsg}`);
          observer.next(response);
          observer.complete();
        },
        (error: any) => {
          observer.error(error);
        },
        this.deviceToPluginInstanceMap[device.id],
        'write',
        [buffer]
      );
    });
  }

  private start(callback: (data: any) => void, options?: any): void {
    Object.assign(this.settings, options || {});

    if (options?.device?.settings?.disconnectOnInactivityTimeoutMS) {
      this.action({ command: 'disconnectOnInactivity', device: options.device }).subscribe();
    }

    if (options?.device) {
      this.startBtSerialSubscription(options.device, callback);
      // this.checkIfEnabledAndStartBtSerialSubscription(options.device, callback);
      return;
    }

    const scannerDevices = this.localSettingsService.getBtDevices(BluetoothDeviceMode.Classic, [BluetoothDeviceType.Scanner]);

    for (const device of scannerDevices || []) {
      if (this.deviceToStartSubscriptionMap[device.id]) continue;

      this.startBtSerialSubscription(device, callback);
      // this.checkIfEnabledAndStartBtSerialSubscription(callback, device);
    }
  }

  private checkIfEnabledAndStartBtSerialSubscription(callback: (data: any) => void, device: BluetoothDevice) {
    cordova.exec(
      () => {
        this.startBtSerialSubscription(device, callback);
      },
      (error: any) => {
        this.log('Tried to start() but bluetooth is disabled.');
      },
      this.defaultPluginInstance, // Always use first plugin instance to perform non device / connection specific calls
      'isEnabled',
      []
    );
  }

  private startBtSerialSubscription(device: BluetoothDevice, callback: (data: any) => void) {
    const pluginInstance = this.deviceToPluginInstanceMap[device.id] || 'BluetoothSerial1';
    if (this.deviceToStartSubscriptionMap[device.id] && this.deviceToStartCallbackMap[device.id]) {
      this.log(`Updating read subscription for device '${device.id}' on pluginInstance '${pluginInstance}'`);
      this.deviceToStartCallbackMap[device.id] = callback;
      return;
    }

    this.log(`Starting read subscription for device '${device.id}' on pluginInstance '${pluginInstance}'`);
    this.deviceToStartCallbackMap[device.id] = callback;
    if (this.settings.readDelimiter) {
      this.deviceToStartSubscriptionMap[device.id] = 'unsubscribe';
      cordova.exec(
        (data: any) => {
          device.isConnected = true;

          if (this.deviceToStartCallbackMap[device.id]) this.deviceToStartCallbackMap[device.id](data);
        },
        (error: any) => {
          this.log(`Error while reading data from device '${device.id}': ${JSON.stringify(error)}`);
          delete this.deviceToStartSubscriptionMap[device.id];
          delete this.deviceToStartCallbackMap[device.id];
        },
        pluginInstance,
        'subscribe',
        [this.settings.readDelimiter]
      );
    } else {
      this.deviceToStartSubscriptionMap[device.id] = 'unsubscribeRaw';
      cordova.exec(
        (data: any) => {
          device.isConnected = true;

          let dataString = String.fromCharCode.apply(null, Array.from(new Uint8Array(data)));
          dataString = dataString.replace(/(?:\r\n|\r|\n|\t)/g, '');

          if (this.deviceToStartCallbackMap[device.id]) this.deviceToStartCallbackMap[device.id](dataString);
        },
        (error: any) => {
          this.log(`Error while reading data from device '${device.id}': ${JSON.stringify(error)}`);
          delete this.deviceToStartSubscriptionMap[device.id];
          delete this.deviceToStartCallbackMap[device.id];
        },
        pluginInstance,
        'subscribeRaw',
        []
      );
    }
  }

  private handlePrinterSatoResponses(device: BluetoothDevice, data: string, callback?: (data: string) => void) {
    this.printerSatoResponseMap[device.id] += (data || '').replace(//g, '').replace(/ø/g, '');

    let validJsonResponse;
    let extraChunk = '';
    try {
      validJsonResponse = JSON.parse(this.printerSatoResponseMap[device.id]);
      this.printerSatoResponseMap[device.id] = extraChunk || '';
    } catch (error) {
      // this.log('Partial response: ' + this.printerSatoResponseMap[device.id]);

      // 2 scenarios can occur here: either we don't have 1 complete json yet, or we already have 1 full json + part of another json...
      let numberOfOpenBrackets = 0;
      let numberOfCloseBrackets = 0;
      for (let i = 0; i < this.printerSatoResponseMap[device.id].length; i++) {
        if (this.printerSatoResponseMap[device.id][i] === '{') numberOfOpenBrackets++;
        else if (this.printerSatoResponseMap[device.id][i] === '}') numberOfCloseBrackets++;

        if (!numberOfOpenBrackets || numberOfOpenBrackets !== numberOfCloseBrackets) continue;

        // lets try this again with a valid json and keep the remaining chunk for future handling
        try {
          validJsonResponse = JSON.parse(this.printerSatoResponseMap[device.id].substring(0, i + 1));
          extraChunk = this.printerSatoResponseMap[device.id].substring(i + 1);
          this.printerSatoResponseMap[device.id] = extraChunk || '';
          break;
        } catch (error) {
          this.log('Failed to parse part of the partial response: ' + this.printerSatoResponseMap[device.id].substring(0, i + 1));
        }
      }
      if (!validJsonResponse) return; // return and wait for another chunk...
    }

    if (validJsonResponse.reply != this.printerSatoSeqMap[device.id]) {
      const msg = JSON.stringify(validJsonResponse);
      this.log('Got message from printer: ' + msg);
      if (callback) callback(msg);
      return;
    }

    this.log(`Printer response to write command (${~~(performance.now() - this.printerSatoWritePreviousNow)}ms): ${JSON.stringify(validJsonResponse)}`);
    if (callback) callback(JSON.stringify(validJsonResponse));

    if (this.printerSatoTimeoutMap[device.id]) {
      clearTimeout(this.printerSatoTimeoutMap[device.id]);
      delete this.printerSatoTimeoutMap[device.id];
    }

    if (
      /** @deprecated */ validJsonResponse.type === 2
      || validJsonResponse.type === 3
      || validJsonResponse.type === 103
    ) {
      device.settings.hardwareModel = validJsonResponse.content?.hw;
      device.settings.hardwareId = validJsonResponse.content?.id;
      device.settings.serialnumber = validJsonResponse.content?.sn;
      device.settings.firmware = validJsonResponse.content?.fw;
      device.settings.aepConnectorVersion = validJsonResponse.content?.cv;

      device.settings.rfid = validJsonResponse.content?.os?.rfid;
      device.settings.dpi = validJsonResponse.content?.hd?.dpi;

      device.settings.link = device.settings.link || {};
      device.settings.link.status = validJsonResponse.content?.l?.status != null ? validJsonResponse.content.l.status : device.settings.link.status;
      device.settings.link.scopeFlags = validJsonResponse.content?.l?.sf != null ? validJsonResponse.content.l.sf : device.settings.link.scopeFlags;
      device.settings.link.deviceToken = validJsonResponse.content?.l?.dt || device.settings.link.deviceToken;

      device.settings.link.sessionInformation = device.settings.link.sessionInformation || {};
      device.settings.link.sessionInformation.deviceToken = validJsonResponse.content?.l?.st || device.settings.link.sessionInformation.deviceToken;

      this.localSettingsService.addOrUpdateBtDevice(device);
    }

    if (!this.printerSatoWriteObserverMap[device.id]) return;

    if (!validJsonResponse.result) { // AepConnectorMessageResult.Unknown
      this.printerSatoWriteObserverMap[device.id].error('Unknown Error');
    } else if (validJsonResponse.result === 1) { // AepConnectorMessageResult.Success
      this.printerSatoWriteObserverMap[device.id].next(validJsonResponse);
      this.printerSatoWriteObserverMap[device.id].complete();
    } else if (validJsonResponse.result === 10) { // AepConnectorMessageResult.Unlinked
      this.printerSatoWriteObserverMap[device.id].error('Printer Unlinked');
    } else if (validJsonResponse.result === 11) { // AepConnectorMessageResult.Busy
      this.printerSatoWriteObserverMap[device.id].error('Printer Busy');
    } else if (validJsonResponse.result === 12) { // AepConnectorMessageResult.NoScopeAccess
      this.printerSatoWriteObserverMap[device.id].error('NoScopeAccess');
    } else if (validJsonResponse.result === 13) { // AepConnectorMessageResult.UnknownMessage
      this.printerSatoWriteObserverMap[device.id].error('Unknown Message');
    } else if (validJsonResponse.result === 14) { // AepConnectorMessageResult.Unhandled
      this.printerSatoWriteObserverMap[device.id].error('Unhandled Message');
    } else if (validJsonResponse.result === 15) { // AepConnectorMessageResult.ProtocolBusy
      this.printerSatoWriteObserverMap[device.id].error('Protocol Busy');
    } else if (validJsonResponse.result === 100) { // AepConnectorMessageResult.Error
      this.printerSatoWriteObserverMap[device.id].error(`${validJsonResponse.ecode ? validJsonResponse.ecode + ' - ' : ''}${validJsonResponse.etext}`);
    }
    delete this.printerSatoWriteObserverMap[device.id];
  }

  private handleScanData(data: string) {
    this.scannerService.emitScan({
      source: 'BT SCANNER',
      value: data,
      valueType: '',
    });
  }

  private stop(options?: any): void {
    for (const deviceId of Object.keys(this.deviceToStartSubscriptionMap)) {
      const device = this.localSettingsService.getBtDevice(deviceId);
      if (!device) continue;
      if (!options?.device || options.device.id !== deviceId) continue;

      if (device.type === BluetoothDeviceType.Scanner) continue; // keep the subscription open as we want to allow the user to "keep scanning". ofc he'll get an error if the current control doesn't accept scanning
      if (device.type === BluetoothDeviceType.PrinterSato && (this.writeInProgress || this.printerSatoWriteObserverMap[device.id])) continue; // keep the subscription open as we're actively waiting for a printer reply;

      this.log(`Stopping read subscription for device '${device.id}' on pluginInstance '${this.deviceToPluginInstanceMap[device.id]}'`);
      cordova.exec(
        (data: any) => {
          // device.isConnected = false;
          delete this.deviceToStartSubscriptionMap[device.id];
        },
        (error: any) => {
          this.log(`Error while unsubscribing from device '${device.id}': ${JSON.stringify(error)}`);
          device.isConnected = false;
          delete this.deviceToStartSubscriptionMap[device.id];
        },
        this.deviceToPluginInstanceMap[device.id] || 'BluetoothSerial1',
        this.deviceToStartSubscriptionMap[device.id],
        []
      );
    }
  }

  status(): Observable<any> {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Cordova not available...');
      this.isPluginAllowedChecked = true;
      return of('Cordova not available...');
    }

    return new Observable((observer: Observer<any>) => {
      cordova.exec(
        () => {
          observer.next({
            enabled: true,
            connections: this.deviceToPluginInstanceMap,
            devices: this.connectedDevices,
            log: Array.from(this.logRingBuffer),
          });
          observer.complete();
        },
        (error: any) => {
          observer.next({
            enabled: false,
            connections: this.deviceToPluginInstanceMap,
            devices: this.connectedDevices,
            log: Array.from(this.logRingBuffer),
          });
          observer.complete();
        },
        this.defaultPluginInstance, // Always use first plugin instance to perform non device / connection specific calls
        'isEnabled',
        []
      );
    });
  }

  private getKnownDevice(device: BluetoothDevice) {
    return this.connectedDevices.find((d: BluetoothDevice) => {
      return d.mode === device.mode && d.id === device.id;
    });
  }

}
